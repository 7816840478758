import { disableEngineSpeeds, enableEngineSpeeds } from '@/api/aqenginespeeds';

const disableEngineSpeed = {
	id: 'disableEngineSpeed',
	selectionType: 'multiple',
	label: 'aqenginespeeds.actions.disableEngineSpeed',
	functionality: 'UPDATE_AQENGINESPEEDS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqenginespeeds.actions.disableEngineSpeed');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqenginespeedsid: registries[i].aqenginespeedsid
			});
		}

		const data = await disableEngineSpeeds(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableEngineSpeed = {
	id: 'enableEngineSpeed',
	selectionType: 'multiple',
	label: 'aqenginespeeds.actions.enableEngineSpeed',
	functionality: 'UPDATE_AQENGINESPEEDS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqenginespeeds.actions.enableEngineSpeed');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqenginespeedsid: registries[i].aqenginespeedsid
			});
		}

		const data = await enableEngineSpeeds(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableEngineSpeed, enableEngineSpeed]
};
